import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import React from "react";
import GlobalContextProvider from "./src/store/GlobalContextProvider";
const { navigate } = require("gatsby");

const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider>{element}</GlobalContextProvider>;
};

const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation?.pathname === location.pathname) {
    // BUGFIX: https://github.com/gatsbyjs/gatsby/issues/25424
    navigate(location.pathname, {
      replace: true,
    });
  }
};

export { wrapRootElement, onPreRouteUpdate };

window.addEventListener("scroll", function () {
  let header = document.getElementById("header");
  if (header) {
    if (window.scrollY === 0) {
      header.classList.add("header-no-scroll");
      header.classList.remove("header-scroll");
    } else {
      header.classList.add("header-scroll");
      header.classList.remove("header-no-scroll");
    }
  }
});

if (
  [
    "https://christianblue.ithands.net/",
    "https://christianblue.rubico.dev/",
  ].includes(window.location.href)
) {
  var script = document.createElement("script");
  script.src =
    "https://www.bugherd.com/sidebarv2.js?apikey=8ugxynpv0v9tbuyp7nioia";
  script.async = true;
  document.body.appendChild(script);
  // bugherd
}

if(["https://christianblue.com/"].includes(window.location.href)){
  var gTagScript = document.createElement("script");
  gTagScript.src =
  "https://www.googletagmanager.com/gtag/js?id=G-GTVVJVRW7B";
  gTagScript.async = true;
  document.body.appendChild(gTagScript);
}

window.addEventListener("load", function () {
  if(["https://christianblue.com/"].includes(window.location.href)){
    var layerScript = document.createElement("script");
    layerScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-GTVVJVRW7B');
    `;
    document.body.appendChild(layerScript);
  }
})