import React from "react";

export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

const initialState = {
  tab: "active",
  timer: 0,
  maxTimer: 33,
  locationMaxRetries: 5,
  locationDelay: 3000,
  locationToggle: false,
  longitude:"",
  latitude:"",
};

function reducer(state, action) {
  switch (action.type) {
    case "InActive": {
      return {
        ...state,
        tab: "inactive",
      };
    }
    case "Active": {
      return {
        ...state,
        tab: "active",
      };
    }
    case "locationChange": {
      return {
        ...state,
        locationToggle: !state.locationToggle,
      };
    }
    case "latitudeandlongitude":{
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude,
      }
    }
    default:
      throw new Error("Bad Action Type");
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
